/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!!  开发环境请不要不要不要直接修改 Config   !!!
 * !!!  如果需要修改请在最下方的安全编辑区域修改  !!!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */

const Config = {
  HAS_HEADER: false,
  HTTP_URL: process.env.VUE_APP_API_HOST,
  HTTP_APPLY_URL: process.env.VUE_APP_APPLY_API_HOST,
  HTTP_SW_URL: process.env.VUE_APP_API_SW_HOST,
  HTTP_GW_URL: process.env.VUE_APP_API_GW_HOST,
  HTTP_CF_URL: process.env.VUE_APP_COSFIG_HOST,
  HTTP_TIMEOUT: 60000,
  VERSION: '1.2.230402',
  COUNT_DOWN: 60,
  SYSTEM_INFO: {},
}

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!!  安全区域 !!!!!!!!!!!!!!!!!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */
export default Config
