const routes = [
  // 登录页
  {
    path: '/login',
    name: 'LoginForm',
    meta: {
      title: ''
    },
    component: () => import('@/views/user/LoginForm.vue')
  },
  // 支付回调
  {
    path: '/payback',
    name: 'PayBack',
    meta: {
      title: ''
    },
    component: () => import('@/views/user/PayBack.vue')
  },
  {
    path: '/paybackB',
    name: 'PayBackB',
    meta: {
      title: ''
    },
    component: () => import('@/views/user/PayBackB.vue')
  }
]

export default routes