const routes = [
  // 落地页
  {
    path: '/third/landing',
    name: 'ApplyLanding',
    meta: {
      title: 'ETC办理',
      documentTitle: 'ETC办理'
    },
    component: () => import('@/views/third/ApplyLanding.vue')
  },
  // 授权中转
  {
    path: '/third/transfer',
    name: 'TransferPage',
    meta: {
      title: 'ETC办理',
      documentTitle: 'ETC办理'
    },
    component: () => import('@/views/third/TransferPage.vue')
  },
  // app 跳小程序内置页面
  {
    path: '/third/coupon',
    name: 'ApplyCoupon',
    meta: {
      title: 'ETC办理',
      documentTitle: 'ETC办理',
      region: '打开异业特定权益H5领取页面',
    },
    component: () => import('@/views/third/ApplyCoupon.vue')
  },
  {
    path: '/third/emall',
    name: 'ApplyEmall',
    meta: {
      title: 'ETC办理',
      documentTitle: 'ETC办理',
      region: 'h5电商',
    },
    component: () => import('@/views/third/ApplyEmall.vue')
  },
]
export default routes