const routes = [
  // 添加地址
  {
    path: '/apply/addaddress',
    name: 'AddAddress',
    meta: {
      title: '收货地址',
      requiresAuth: true,
      region: '地址填写页',
    },
    component: () => import('@/views/apply/AddAddress.vue')
  },
  // 商品详情
  {
    path: '/apply/productdetail',
    name: 'ProductDetail',
    meta: {
      title: 'ETC助手',
      requiresAuth: false,
      hideHomeBtn: true,
      region: '付费ETC商品页',
    },
    component: () => import('@/views/apply/ProductDetail.vue')
  },
  {
    path: '/apply/productlist',
    name: 'ProductList',
    meta: {
      title: '选择办理方式',
      requiresAuth: false,
      hideHomeBtn: true,
      region: '选择办理方式页',
    },
    component: () => import('@/views/apply/ProductList.vue')
  },
  // 订单详情
  {
    path: '/apply/orderdetail/:no',
    name: 'OrderDetail',
    meta: {
      title: 'ETC助手',
      requiresAuth: true,
      hideHomeBtn: true,

    },
    component: () => import('@/views/apply/OrderDetail.vue')
  },
  // 订单列表
  {
    path: '/apply/orderlist',
    name: 'OrderList',
    meta: {
      title: '订单列表',
      requiresAuth: true,
      hideHomeBtn: true,
    },
    component: () => import('@/views/apply/OrderList.vue')
  },
  // 物流信息
  {
    path: '/apply/expressinfo',
    name: 'ExpressInfo',
    meta: {
      title: '物流信息',
      requiresAuth: true,
      hideHomeBtn: true,
    },
    component: () => import('@/views/apply/ExpressInfo.vue')
  },
  // 激活引导
  {
    path: '/apply/activeguide',
    name: 'ActiveGuide',
    meta: {
      title: '激活绑定ETC',
      requiresAuth: true,
    },
    component: () => import('@/views/apply/ActiveGuide.vue')
  },
  // 退货引导
  {
    path: '/apply/refundguide',
    name: 'RefundGuide',
    meta: {
      title: '退货退款流程',
      requiresAuth: true,
    },
    component: () => import('@/views/apply/RefundGuide.vue')
  },
  // 银行申办
  {
    path: '/apply/bank',
    name: 'BankApply',
    meta: {
      title: 'ETC助手',
      requiresAuth: true,
      hideHomeBtn: true,
      region: '免费ETC商品页',
    },
    component: () => import('@/views/apply/BankApply.vue')
  },
  // 银行申办进度
  {
    path: '/apply/bankprogress',
    name: 'BankPorgress',
    meta: {
      title: 'ETC助手',
      requiresAuth: true,
      hideHomeBtn: true,
      region: '办卡详情页',
    },
    component: () => import('@/views/apply/BankPorgress.vue')
  },
  
  
]
export default routes