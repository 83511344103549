import ETC_AXIOS from './etc.axios'
import APP from '../config/app'
import ETC from '@/config/etc'
import { THIRD_TYPE } from '@/config/base'
// 判断是否有权益
export const apiCheckRights = (data) => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/product-order/check-rights', {third_type: THIRD_TYPE, ...data})
}

// 领取权益
export const apiDrawRights = (data) => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/product-order/draw-rights', {third_type: THIRD_TYPE, ...data})
}

// 取消权益
export const apiCancelRights = (data) => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/product-order/cancel-rights', {third_type: THIRD_TYPE, ...data})
}

// 获取地址
export const apiGetAddress = (data) => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/product-order/get-address', {...data})
}

// 新增/修改收货地址
export const apiModifyAddress = (data) => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/product-order/modify-address', {...data})
}

// 创建订单
export const apiSubmitOrder = (data) => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/product-order/submit-order', {
    third_type: THIRD_TYPE,
    real_source: localStorage.getItem(ETC.LOCAL_STORAGE_KEY.PL),
    ...data
  })
}

// 支付
export const apiPurchase = (data) => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/product-order/purchase', {third_type: THIRD_TYPE, ...data})
}

// 订单列表
export const apiGetOrderList = data => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/product-order/get-order-list', {...data})
}

// 获取订单详细
export const apiGetOrderInfo = data => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/product-order/get-order-info', {...data})
}

// 订单支付结果查询
export const apiCheckHasPay = data => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/product-order/check-has-paid', {...data})
}


// 获取订单物流信息
export const apiGetOrderExpress = data => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/product-order/get-order-express', {...data})
}

// 获取产品包列表
export const apiGetProductList = data => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/apply/get-product-package-list', {...data})
}

// 获取产品包列表无需登录
export const apiGetProductListNologin = data => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/product-package/get-product-package-list', {...data})
}

// 根据term获取优惠券信息
export const apiCouponInfo = data => {
  return ETC_AXIOS.post(APP.HTTP_GW_URL + '/api/activity-mgmt/marketing/pay/coupon/couponInfoListNew', data, {isJson: true, headers: { 'content-type': 'application/json' }})
}
// 获取对应促销位产品包 请求返回顺序一致
// 百度H5 promotions:12、多彩包 14、付费隐藏ETC-H5 7、信用卡
// 百度小程序 promotions:11、多彩包 13、付费隐藏ETC-百度小程序 6、信用卡
export const apiGetProductListByPromotion = data => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/product-package/get-by-promotion', {...data})
}

// 获取最后一个未支付订单
export const apiGetLastOrder = data => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/product-order/get-not-paid-last-one', {...data})
}

// 取消訂單
export const apiCancelOrder = data => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/product-order/cancel', {...data})
}
//新的取消订单
export const apiCancelOrderNew = data => {
  return ETC_AXIOS.post(APP.HTTP_GW_URL + '/api/apply/frontend/product-order/cancel', data, {isJson: true, headers: { 'content-type': 'application/json' }})
}

// 查询银行办理进度
export const apiCheckBankProgress = data => {
  return ETC_AXIOS.post(APP.HTTP_URL + '/activity/free-apply/get-audit-result', {...data})
}

// 获取银行办理跳转路径
export const apiGetBankApplyUrl = data => {
  return ETC_AXIOS.post(APP.HTTP_URL + '/bank/bank-apply-order/apply-order', {...data})
}

// 银行回调数据上传
export const apiBankCallBack = data => {
  return ETC_AXIOS.post(APP.HTTP_URL + '/bank/bank-apply-order/call-back', {...data})
}


// 获取评价列表
export const getComments = data => {
  return ETC_AXIOS.post(APP.HTTP_SW_URL + '/no-login/comments', {...data})
}
// 获取评价列表
export const getApplyGoodsNoLogin = data => {
  return ETC_AXIOS.post(APP.HTTP_SW_URL + '/no-login/goods-detail', {...data})
}

