const routes = [
  // 落地页
  {
    path: '/qa/index',
    name: 'QAindex',
    meta: {
      title: '常见问题',
      documentTitle: '常见问题'
    },
    component: () => import('@/views/qa/index.vue')
  },
  // 授权中转
  {
    path: '/qa/qa-list',
    name: 'serviceQaList',
    meta: {
      title: '问题列表',
      documentTitle: '问题列表'
    },
    component: () => import('@/views/qa/qa-list.vue')
  },
  // app 跳小程序内置页面
  {
    path: '/qa/qa-detail',
    name: 'serviceQaDetail',
    meta: {
      title: '问题详情',
      documentTitle: '问题详情',
    },
    component: () => import('@/views/qa/qa-detail.vue')
  },
]
export default routes