/*
 * @Author: 司徒欣 sheri.situ@wetax.com.cn
 * @Date: 2024-07-31 11:19:07
 * @LastEditors: 司徒欣 sheri.situ@wetax.com.cn
 * @LastEditTime: 2024-08-01 16:20:14
 * @FilePath: /etc_h5_apply/src/api/user.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import ETC_AXIOS from './etc.axios'
import APP from '../config/app'
import { THIRD_TYPE } from '@/config/base'
// 判断是否有办理手机号码登录
export const apiCheckBindPhone = (data = {}) => {
  return ETC_AXIOS.post(APP.HTTP_SW_URL + '/auth-third/check-login', data)
}

// 登录
export const apiLogin = (data = {}) => {
  return ETC_AXIOS.post(APP.HTTP_SW_URL + '/auth-third/login-by-phone', data)
}

// 登出
export const apiLogout = (data = {}) => {
  return ETC_AXIOS.post(APP.HTTP_SW_URL + '/auth-third/logout', {third_type: THIRD_TYPE, ...data})
}

// 验证码
export const apiSenCode = (data = {}) => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/no-login/send-verification-code', data)
}

// 用戶協議獲取
export const apiPactManagePact = (data = {}) => {
  return ETC_AXIOS.post(APP.HTTP_GW_URL + '/api/user/pactManage/pact', data, {isJson: true, headers: { 'content-type': 'application/json' }})
}

// 用戶協議簽署
export const apiPactManageSignedPact = (data = {}) => {
  return ETC_AXIOS.post(APP.HTTP_GW_URL + '/api/user/pactManage/signPact', data, {isJson: true, headers: { 'content-type': 'application/json' }})
}

// 无登陆签署协议
export const apiPactManageNoLoginSignedPact = (data = {}) => {
  return ETC_AXIOS.post(APP.HTTP_GW_URL + '/api/user/pactManage/noLoginSignPact', data, {isJson: true, headers: { 'content-type': 'application/json' }})
}

// 公告获取
export const apiGetNotice = (data = {}) => {
  return ETC_AXIOS.post(APP.HTTP_URL + '/common/get-express-annouce', data)
}

export const apiGetEvaluate = (data = {}) => {
  return ETC_AXIOS.post(APP.HTTP_SW_URL + '/no-login/comments', data)
}

// 广告上报
export const apiAdUpload = (data = {}) => {
  return ETC_AXIOS.post(APP.HTTP_APPLY_URL + '/ad/users-baidu-ad-actions', data)
}