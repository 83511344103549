const routes = [
  // 添加地址
  {
    path: '/applyB/addaddress',
    name: 'AddAddressB',
    meta: {
      title: '收货地址',
      requiresAuth: true,
      region: '地址填写页',
    },
    component: () => import('@/views/applyB/AddAddress.vue')
  },
  // 商品详情
  {
    path: '/applyB/productdetail',
    name: 'ProductDetailB',
    meta: {
      title: 'ETC助手',
      requiresAuth: false,
      hideHomeBtn: true,
      region: '付费ETC商品页',
    },
    component: () => import('@/views/applyB/ProductDetail.vue')
  },
  {
    path: '/applyB/productlist',
    name: 'ProductListB',
    meta: {
      title: '选择办理方式',
      requiresAuth: false,
      hideHomeBtn: true,
      region: '选择办理方式页',
    },
    component: () => import('@/views/applyB/ProductList.vue')
  },
  // 订单详情
  {
    path: '/applyB/orderdetail',
    name: 'OrderDetailB',
    meta: {
      title: 'ETC助手',
      requiresAuth: true,
      //hideHomeBtn: true,

    },
    component: () => import('@/views/applyB/OrderDetail.vue')
  },
  // 订单列表
  {
    path: '/applyB/orderlist',
    name: 'OrderListB',
    meta: {
      title: '订单列表',
      requiresAuth: true,
      hideHomeBtn: true,
    },
    component: () => import('@/views/applyB/OrderList.vue')
  },
  // 物流信息
  {
    path: '/applyB/expressinfo',
    name: 'ExpressInfoB',
    meta: {
      title: '物流信息',
      requiresAuth: true,
      hideHomeBtn: true,
    },
    component: () => import('@/views/applyB/ExpressInfo.vue')
  },
  // 激活引导
  {
    path: '/applyB/activeguide',
    name: 'ActiveGuideB',
    meta: {
      title: '激活绑定ETC',
      requiresAuth: true,
    },
    component: () => import('@/views/applyB/ActiveGuide.vue')
  },
  // 退货引导
  {
    path: '/applyB/refundguide',
    name: 'RefundGuideB',
    meta: {
      title: '退货退款流程',
      requiresAuth: true,
    },
    component: () => import('@/views/applyB/RefundGuide.vue')
  },
  // 银行申办
  {
    path: '/applyB/bank',
    name: 'BankApplyB',
    meta: {
      title: 'ETC助手',
      requiresAuth: true,
      hideHomeBtn: true,
      region: '免费ETC商品页',
    },
    component: () => import('@/views/applyB/BankApply.vue')
  },
  // 银行申办进度
  {
    path: '/applyB/bankprogress',
    name: 'BankPorgressB',
    meta: {
      title: 'ETC助手',
      requiresAuth: true,
      hideHomeBtn: true,
      region: '办卡详情页',
    },
    component: () => import('@/views/applyB/BankPorgress.vue')
  },
  
  
]
export default routes