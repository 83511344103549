const routes = [
  // 客服中心
  {
    path: '/center',
    name: 'CenterIndex',
    meta: {
      title: 'ETC助手',
      hideLeftArrow: true,
      hideHomeBtn: true,
    },
    component: () => import('@/views/center/CenterIndex.vue')
  },
  // 发卡方联系方式
  {
    path: '/center/issuerphone',
    name: 'IssuerPhone',
    meta: {
      title: '发卡方联系方式',
    },
    component: () => import('@/views/center/IssuerPhone.vue')
  },
]

export default routes