// create by nieshu on 2018/11/5
// import Vue from 'vue'

class Util {
  static randomString(len) {
    len = len || 32;
    let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; // 默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    let maxPos = $chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
  }

  static str2Date(str, fmt) {
    if (fmt.length !== str.length) {
      console.log('format is not match string!')
      return new Date()
    }
    let o = {'y+': '', 'M+': '', 'd+': '', 'h+': '', 'm+': '', 's+': '', 'S': ''}
    for (let k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        o[k] = str.substr(fmt.indexOf(RegExp.$1), RegExp.$1.length)
        // console.log(k, o[k]);
      }
    }
    return new Date(o['y+'], o['M+'] - 1, o['d+'], o['h+'], o['m+'], o['s+'], o['S'])
  }
  static loadJS(src) {
    return new Promise((re) => {
      let script = document.createElement('script')
      script.type = 'text/javascript'
      if (script.readyState) {
        script.onreadystatechange = () => {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onreadystatechange = null
            re()
          }
        }
      } else {
        script.onload = () => {
          re()
        }
      }
      script.src = src
      document.body.appendChild(script)
    })
  }
  // /**
  //  * 利用canvas 进行图片压缩
  //  * @param path
  //  * @param obj
  //  * @param callback
  //  */
  // static canvasDataURL(path, obj = {}) {
  //   let self = this
  //   return new Promise((re) => {
  //     let img = new Image()
  //     img.src = path
  //     img.onload = function() {
  //       let that = this
  //       // 默认按比例压缩
  //       let w = that.width
  //       let h = that.height
  //       let quality = 0.5 // 默认图片质量为0.7
  //       // 生成canvas
  //       let canvas = document.createElement('canvas')
  //       let ctx = canvas.getContext('2d')
  //       // 创建属性节点
  //       let anw = document.createAttribute('width')
  //       anw.nodeValue = w
  //       let anh = document.createAttribute('height')
  //       anh.nodeValue = h
  //       canvas.setAttributeNode(anw)
  //       canvas.setAttributeNode(anh)
  //       ctx.drawImage(that, 0, 0, w, h)
  //       // 图像质量
  //       if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
  //         quality = obj.quality
  //       }
  //       // quality值越小，所绘制出的图像越模糊
  //       let base64 = canvas.toDataURL('image/jpeg', quality)
  //       // 回调函数返回base64的值
  //       re({base64, file: self.dataURLtoFile(base64, obj.fileName || 'canvasDataURL.jpg')})
  //     }
  //   })
  // }
  /**
   * base64 转 blob
   * @param dataurl
   * @param fileName
   * @returns {Blob}
   */
  static dataURLtoFile(dataurl, fileName) { // 将base64转换为文件
    let arr = dataurl.split(',')
    let mime = arr[0].match(/:(.*?);/)[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let blob = new Blob([u8arr], { type: mime })
    blob.lastModifiedDate = new Date()
    blob.name = fileName
    return blob
  }

  /**
   * html 转换
   * @param str
   * @returns {string}
   */
  static htmlDecodeByRegExp (str) {
    let s = '';
    if (str.length === 0) return '';
    s = str.replace(/&amp;/g, '&');
    s = s.replace(/&lt;/g, '<');
    s = s.replace(/&gt;/g, '>');
    s = s.replace(/&nbsp;/g, ' ');
    s = s.replace(/&#39;/g, '\'');
    s = s.replace(/&quot;/g, '\'');
    return s;
  }

  /**
   * json 格式转 form表单
   * @param obj
   * @returns {*}
   */
  static json2formData(obj) {
    if (obj && !(obj instanceof FormData)) {
      let formData = new FormData()
      for (let k in obj) {
        if (obj[k] != null) {
          formData.append(k, obj[k])
        }
      }
      return formData
    }
    return obj
  }
  /**
   * 创建defer对象
   * @returns {{promise: Promise<any>, resolve: *, reject: *}}
   */
  static createDefer() {
    let resolve
    let reject
    let promise = new Promise((re, rj) => {
      resolve = re
      reject = rj
    })
    return {promise, resolve, reject}
  }
  /**
   * 深度 assign 对象
   * @param target
   * @param source
   */
  static deepAssign(target = {}, source) {
    for (let i in source) {
      if (!target[i]) {
        target[i] = source[i]
      } else if (typeof source[i] === 'object' && !(source[i] instanceof Array)) {
        this.deepAssign(target[i], source[i])
      } else {
        target[i] = source[i]
      }
    }
    return target
  }
  /**
   * 数组划分为2维数组
   * @param array
   * @returns {Array}
   */
  static arrSplit2(array) {
    let result = []
    let arr = []
    array.map((v, i) => {
      if (i % 2 === 0) arr = []
      arr.push(v)
      if (arr.length === 2) result.push(arr)
    })
    if (arr.length === 1) {
      arr.push({})
      result.push(arr)
    }
    return result
  }
  /**
   * 判断是否为空
   * @param obj
   */
  static isNull(obj) {
    if (obj instanceof Array) return obj.length === 0
    if (obj instanceof Object) return JSON.stringify(obj) === '{}'
    return !obj
  }
  static dateCount(date, num, unit) {
    switch (unit) {
      case 'y':
        date.setFullYear(date.getFullYear() + num);
        break;
      case 'M':
        date.setMonth(date.getMonth() + num);
        break;
      case 'd':
        date.setDate(date.getDate() + num);
        break;
      case 'h':
        date.setHours(date.getHours() + num);
        break;
      case 'm':
        date.setMinutes(date.getMinutes() + num);
        break;
      case 's':
        date.setSeconds(date.getSeconds() + num);
    }
    return date;
  }
  static dateFormat(date, fmt) {
    let o = {
      'M+': date.getMonth() + 1, // 月份
      'd+': date.getDate(), // 日
      'h+': date.getHours(), // 小时
      'm+': date.getMinutes(), // 分
      's+': date.getSeconds(), // 秒
      'S': date.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    for (let k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
    }
    return fmt;
  }
  /**
   * 数字千分位
   * @returns {*}
   */
  static toQuartile(strs, hasPoint, currencyType) {
    let str = strs.split('.');
    let str1 = str[1] ? (str[1].length >= 2 ? str[1].substr(0, 2) : str[1] + '0') : '00';
    let result = '';
    if (hasPoint) {
      result = str[0].replace(/\d{1,3}(?=(\d{3})+$)/g, '$&,') + '.' + str1;
    } else {
      result = str[0].replace(/\d{1,3}(?=(\d{3})+$)/g, '$&,') + (str[1] ? ('.' + str[1]) : '');
    }
    if (currencyType === '$') {
      result = '$' + result;
    } else if (currencyType === '元') {
      result = result + '元';
    } else if (currencyType === '¥') {
      result = '¥' + result;
    }
    return result;
  }

  // static copy(str, msg = '复制成功') {
  //   const input = document.createElement('input');
  //   document.body.appendChild(input);
  //   input.setAttribute('value', str);
  //   input.select();
  //   if (document.execCommand('copy')) {
  //     document.execCommand('copy')
  //     Vue.prototype.bus.$refs.toast.$emit('toast', { msg, class: 'no-mask' })
  //     document.body.removeChild(input)
  //   }
  // }
  static judgePageSize(screenHeight, screenWidth) {
    const scale = (screenHeight / screenWidth).toFixed(2)
    if (scale > 1.85) return 'huge'
    if (scale > 1.8) return 'large'
    if (scale < 1.7) return 'small'
    return 'middle'
  }

  static isElementNotInViewport = function(el) {
    if (el) {
      let rect = el.getBoundingClientRect();
      return (
        rect.top >=
          (window.innerHeight || document.documentElement.clientHeight) ||
        rect.bottom <= 0
      );
    }
  };
}

export default Util
