<template>
  <!-- <NavBar /> -->
  <router-view v-if="hasInit" />
</template>
<script>
// import NavBar from '@/components/NavBar.vue';
import { mapState, mapActions, mapMutations } from 'vuex'
import { biInit } from '@/api/bi'
import ETC from '@/config/etc'
import qs from 'qs'
export default {
  data() {
    return {
      hasInit: false
    }
  },
  components: {
    // NavBar
  },
  computed: mapState(['entryInfo']),
  methods: {
    ...mapActions(['appInit', 'uploadEntryInfo']),
    ...mapMutations(['setToken', 'setEntryInfo']),
    handleParams(params) {
      let applyInfo = JSON.parse(localStorage.getItem('applyInfo'))
      this.setEntryInfo({
        bd_vid: params?.bd_vid || '',
        pl: params?.pl || this.entryInfo.pl || applyInfo?.pl || -1,
        entry: params?.entry || params?.entry_id || 1000,
        url: location.href,
        hasUpload: false
      })
      this.uploadEntryInfo()
    }
  },
  async created() {
    const query = qs.parse(location.search.substring(1))
    console.log('beforeCreate query ==>', query)
    this.handleParams(query)
    if (query.channel) {
      // 渠道信息初始化
      const token = query.token || localStorage.getItem(ETC.LOCAL_STORAGE_KEY.TOKEN) || ''
      this.setToken(token)
      console.log('渠道信息初始化')
      await this.appInit({
        channel: query.channel || '',
        token: token
      })
    }
    if (query.redirect) {
      this.$router.replace(query.redirect)
    }
    this.hasInit = true
    biInit()
  }
}
</script>
<style lang="less">
html, body {
  height: 100%;
}
// .van-nav-bar {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100;
//   .van-icon {
//     color: #666;
//   }
// }
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  font-size: 28px;
  line-height: 40px;
}



</style>
